import { User } from 'firebase/auth';
import { ThunkAction } from 'redux-thunk';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { GachaCabinet, GachaCabinets } from '@services/hafh/types/generated';

type GachaCabinetActions = UpdateGachaCabinetAction | UpdateGachaCabinetsAction;

interface GachaCabinetState {
  gachaCabinet: GachaCabinet | null;
  gachaCabinets: GachaCabinets;
}

type ThunkResult<R> = ThunkAction<
  R,
  GachaCabinet,
  undefined,
  GachaCabinetActions
>;

interface UpdateGachaCabinetAction {
  payload: GachaCabinet;
  type: typeof UPDATE_GACHA_CABINET;
}

interface UpdateGachaCabinetsAction {
  payload: GachaCabinets;
  type: typeof UPDATE_GACHA_CABINETS;
}

const api = apiClient(API_TYPES.API);

const initialState: GachaCabinetState = {
  gachaCabinet: null,
  gachaCabinets: [],
};

// Action types
export const UPDATE_GACHA_CABINET = 'hafh/gachaCabinet/UPDATE_GACHA_CABINET';
export const UPDATE_GACHA_CABINETS = 'hafh/gachaCabinet/UPDATE_GACHA_CABINETS';

// Reducer
const gachaCabinet = (
  state = initialState,
  action: GachaCabinetActions
): GachaCabinetState => {
  switch (action.type) {
    case UPDATE_GACHA_CABINET:
      return {
        ...state,
        gachaCabinet: action.payload,
      };
    case UPDATE_GACHA_CABINETS:
      return {
        ...state,
        gachaCabinets: action.payload,
      };
    default:
      return state;
  }
};

// Action creators
export const updateGachaCabinet = (
  gachaCabinet: GachaCabinet
): UpdateGachaCabinetAction => ({
  payload: gachaCabinet,
  type: UPDATE_GACHA_CABINET,
});

export const updateGachaCabinets = (
  gachaCabinets: GachaCabinets
): UpdateGachaCabinetsAction => ({
  payload: gachaCabinets,
  type: UPDATE_GACHA_CABINETS,
});

// Thunk action creators
export const getGachaCabinet =
  (
    gachaCabinetCode: string,
    locale: string,
    authUser: User,
    errorCallback?: () => void
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const gachaCabinet = await api.get(
      `gacha_cabinets/${gachaCabinetCode}`,
      {},
      locale,
      authUser,
      undefined,
      errorCallback
    );

    if (gachaCabinet) {
      dispatch(updateGachaCabinet(gachaCabinet));
    }
  };

export const getGachaCabinets =
  (locale: string, authUser: User): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const gachaCabinets = await api.get('gacha_cabinets', {}, locale, authUser);

    if (gachaCabinets) {
      dispatch(updateGachaCabinets(gachaCabinets));
    }
  };

export default gachaCabinet;
